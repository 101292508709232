import { BANNER_TYPE_HERO, BANNER_TYPE_LANDSCAPE } from './utils/constant';
import React, { useMemo } from 'react';

import { AnchorLink } from '../anchorLink';
import { BannerContent } from './bannerContent';
import { BannerGeneric } from './bannerGeneric';
import { BannerLink } from './bannerLink';
import { breakpoint } from '../../utility/constant';
import { gtag } from 'utility/functions';
import styles from './BannerBasic.module.scss';
import { useGetUrlPromo } from 'hooks/useGetPromoUrl';
import { useRouter } from 'next/router';
import { BannerBasicProps, BannerImages } from '.';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export const BannerBasic = ({ dataBanner, bannerType }: BannerBasicProps) => {
  const { asPath } = useRouter();
  const slug = asPath.split('/')[asPath.split('/').length - 1];

  const { backgroundPattern, cta, images, ...restOfBanner } = dataBanner || {};

  const { doCheck } = useWindowSize();

  const [isSM] = doCheck([{ comparer: enComparer.less, size: breakpoint.sm }]);

  const getSrcImage = (images: BannerImages, bannerType: string): Partial<BannerImages> => {
    let landscape: { srcDesktop: string; srcMobile: string } | undefined,
      alt: string,
      portrait: { src: string } | undefined;
    if (bannerType === BANNER_TYPE_LANDSCAPE || bannerType === BANNER_TYPE_HERO) {
      landscape = images?.landscape;
      alt = images?.alt;
      return { landscape, alt };
    }
    (portrait = images?.portrait), (alt = images?.alt);
    return { portrait, alt };
  };
  const srcImage = useMemo(() => getSrcImage(images, bannerType), [images, bannerType]);
  const bannerData = {
    ...restOfBanner,
    srcImage,
  };

  const { href, target } = useGetUrlPromo(cta?.url ?? '', slug);

  return (
    <React.Fragment>
      {dataBanner && (
        <React.Fragment>
          {isSM ? (
            <BannerLink
              href={href}
              bannerType={bannerType}
              className={styles.container}
              style={{
                backgroundColor: dataBanner.backgroundColor,
                backgroundImage: `${backgroundPattern && 'url(' + backgroundPattern + ')'}`,
              }}
            >
              <BannerContent bannerContent={bannerData} />
            </BannerLink>
          ) : (
            <BannerGeneric
              className={styles.container}
              bannerType={bannerType}
              style={{
                backgroundColor: dataBanner.backgroundColor,
                backgroundImage: `${backgroundPattern && 'url(' + backgroundPattern + ')'}`,
              }}
            >
              <BannerContent bannerContent={bannerData}>
                {cta?.url && (
                  <AnchorLink
                    href={href}
                    target={target}
                    className={styles.cta}
                    onClick={() =>
                      gtag({ section: slug ?? 'Banner', detail: 'Banner', event: 'Click Scopri banner' }, true)
                    }
                  >
                    {cta.label}
                  </AnchorLink>
                )}
              </BannerContent>
            </BannerGeneric>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
