import { BannerType } from 'utility/types';
import Link from 'next/link';
import React from 'react';
import { isInternalLink } from 'utility/functions';

export type BannerLinkProps = {
  style: React.CSSProperties;
  className: string;
  href: string;
  bannerType: BannerType;
  children: React.ReactNode;
};
export const BannerLink = ({ href, style, children, className, bannerType }: BannerLinkProps) => {
  if (isInternalLink(href)) {
    return (
      <Link href={href} className={className} style={style} data-banner-type={bannerType} suppressHydrationWarning>
        {children}
      </Link>
    );
  }

  return (
    <a href={href} className={className} style={style} data-banner-type={bannerType} suppressHydrationWarning>
      {children}
    </a>
  );
};
