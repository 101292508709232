import { BANNER_TYPE_HERO } from 'components/banner/utils/constant';
import { Keyboard, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Banner, BannerBasic } from '..';
import { Wrapper } from '../../wrapper';
import styles from './BannerHero.module.scss';
export type BannerHeroProps = {
  banners: Banner[];
  noPaddingLeft?: boolean;
};

export const BannerHero: React.FC<BannerHeroProps> = ({ banners, noPaddingLeft }: BannerHeroProps) => {
  return (
    <Wrapper
      noPaddingRight={false}
      noPaddingLeft={noPaddingLeft}
      noPaddingTop
      noPaddingBottom
      bgColorTransparent={true}
    >
      <div className={styles.container}>
        {banners.length > 1 ? (
          <BannerHeroCarousel banners={banners} />
        ) : (
          <BannerBasic dataBanner={banners[0]} bannerType={BANNER_TYPE_HERO} />
        )}
      </div>
    </Wrapper>
  );
};

export const BannerHeroCarousel = ({ banners }: { banners: Banner[] }) => {
  return (
    <Swiper
      className={styles.heroSwiper}
      slidesPerView={1}
      spaceBetween={30}
      grabCursor={true}
      updateOnWindowResize={true}
      watchOverflow={true}
      keyboard={{
        enabled: true,
      }}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return '<span class="' + styles.bulletBanner + ' ' + className + '">' + '</span>';
        },
      }}
      modules={[Keyboard, Pagination]}
    >
      {banners?.map((banner) => {
        return (
          <SwiperSlide key={banner.id}>
            <BannerBasic dataBanner={banner} bannerType={BANNER_TYPE_HERO} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
