import { DatoCmsUniqueBanner, DatoCmsVisualComposer } from 'lib/datoCms/types';
import { PROVENIENCE_QUERY_PARAM, isHappybetSite } from 'utility/constant';

import { DatoCmsGamesPage } from 'lib/types/page';
import { PROMO_MODEL_API_KEY } from 'lib/datoCms/constants';
import { SitemapPage } from './types';

export const filterBannerUniqueByModelApiKey = (arr: DatoCmsUniqueBanner[], modelApiKey: string) => {
  return arr.filter((component) => component._modelApiKey === modelApiKey);
};

export const filterVisualComposerByModelApiKey = (arr: DatoCmsVisualComposer[], modelApiKey: string) => {
  return arr.filter((component) => component._modelApiKey === modelApiKey);
};

export function generateSiteMapPageByPageTop(page: DatoCmsGamesPage): SitemapPage {
  const { heroBanner, visualComposer } = page;
  return {
    slug: page.slug,
    heroBanners: heroBanner.length > 0 && heroBanner[0]._modelApiKey === PROMO_MODEL_API_KEY ? true : false,
    portraitBanner:
      page.bannerInsideList && page.bannerInsideList![0]._modelApiKey === PROMO_MODEL_API_KEY ? true : false,
    pageBanners: filterVisualComposerByModelApiKey(visualComposer!, PROMO_MODEL_API_KEY).length,
  };
}

export function generateSiteMapPageByPageCategory<T>(page: DatoCmsGamesPage | (DatoCmsGamesPage & T)): SitemapPage {
  const { slug, heroBanner, bannerOnPage } = page;
  return {
    slug,
    heroBanners: heroBanner.length > 0 && heroBanner[0]._modelApiKey === PROMO_MODEL_API_KEY ? true : false,
    portraitBanner:
      !!page.bannerInsideList?.length && page.bannerInsideList[0]._modelApiKey === PROMO_MODEL_API_KEY ? true : false,
    pageBanners: bannerOnPage!.length > 0 && bannerOnPage![0]._modelApiKey === PROMO_MODEL_API_KEY ? 1 : 0,
  };
}

export const getPromoUrl = (url: string, slugProvenienza: string) => {
  if (url.includes('registrati') || url.includes('register')) {
    let localSlug = `${slugProvenienza}`;

    if (/^\?/.test(localSlug)) {
      localSlug = `&${localSlug.substring(1)}`;
    } else if (/\?/.test(localSlug)) {
      localSlug = localSlug.replace('?', '&');
    }

    return `${url}?${PROVENIENCE_QUERY_PARAM}=${`${isHappybetSite ? 'newhpybet_' : 'newsnai_'}${localSlug}`}`;
  }

  return `/login?urlSuccess=${encodeURIComponent(url)}&urlClose=${encodeURIComponent(slugProvenienza)}`;
};

export const getTargetType = (url: string): '_blank' | '_self' => {
  const urlParams = new URLSearchParams(url);
  return urlParams.get('urlSuccess') ? '_self' : '_blank';
};
